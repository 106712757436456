import {
    useCallback,
} from 'react'

import useField from '@src/hooks/useField'

const useFormSelectOnChangeBlurHandling = ({
    name, onChange, onBlur,
}: {
    name: string,
    onChange?: (name: string, value: string) => void,
    onBlur?: (name: string) => void,
}) => {
    const field = useField({
        name,
    })

    const handleChange = useCallback(
        (fieldName: string, value: string) => {
            field.context.setFieldValue(fieldName, value)
            if (onChange) {
                onChange(fieldName, value)
            }
        },
        [
            field.context,
            onChange,
        ],
    )

    const handleBlur = useCallback(
        (fieldName: string) => {
            field.context.setFieldTouched(fieldName, true)
            if (onBlur) {
                onBlur(fieldName)
            }
        },
        [
            field.context,
            onBlur,
        ],
    )

    return {
        handleChange,
        handleBlur,
        field,
    }
}

export default useFormSelectOnChangeBlurHandling
