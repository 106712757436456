import React, {
    useCallback, useEffect, useMemo,
} from 'react'
import {
    useNavigate,
    useLocation,
} from 'react-router-dom'
import apiHooks from '@src/api/hooks/apiHooks'
import parseErrorMessage, {
    IError,
} from '@src/utils/parseErrorMessage'
import ErrorWithSupportPage from '../ScanQRError/ErrorWithSupportPage'
import Confirm from './components/Confirm'

function ConfirmPage(): JSX.Element {
    const navigate = useNavigate()
    const location = useLocation()
    const {
        mutate: acceptMutate,
        isSuccess,
        isPending,
        reset,
        error,
    } = apiHooks.terms.useAcceptTerms()

    const handleConfirm = useCallback(() => {
        acceptMutate(null)
    }, [acceptMutate])

    useEffect(() => {
        if (isSuccess) {
            apiHooks.terms.updateHasAcceptedTermsCache({
                hasSignedTcAndGuide: true,
            })
            navigate('/redirect', {
                state: {
                    origin: location.pathname,
                },
                replace: true,
            })
        }
    }, [
        isSuccess,
        navigate,
        location.pathname,
    ])

    const errorMessage = useMemo(() => {
        return error ? parseErrorMessage(error as unknown as IError) : undefined
    }, [error])

    if (error) {
        return (
            <ErrorWithSupportPage
                message={errorMessage}
                onBackClick={reset}
            />
        )
    }
    return (
        <Confirm
            isLoading={isPending}
            onConfirm={handleConfirm}
        />
    )
}

export default ConfirmPage
