import React, {
    ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react'

import Typography from '../Typography'

import classes from './Select.style'

export interface ISelectOption<TValue = object> {
    label: ReactNode
    value: string
    data?: TValue
}
export type SelectProps = {
    name: string
    options: ISelectOption[]
    value?: string
    onChange?: (name: string, value: string) => void
    onBlur?: (name: string) => void
    placeholder?: ReactNode
    label?: ReactNode
    helperText?: ReactNode
    allowEmpty?: boolean
    disabled?: boolean,
    getOptionLabel?: (name: ISelectOption<object>) => string
}

const EMPTY: ISelectOption = {
    label: '',
    value: '',
}

function Select({
    name,
    label,
    helperText,
    onBlur,
    onChange,
    allowEmpty,
    disabled,
    getOptionLabel,
    options: originalOptions = [],
    ...rest
}: SelectProps): JSX.Element {
    const [
        value,
        setValue,
    ] = useState<string | undefined>('')

    const options = useMemo(() => {
        return allowEmpty ? [
            EMPTY,
            ...originalOptions,
        ] : originalOptions
    }, [
        originalOptions,
        allowEmpty,
    ])

    useEffect(() => {
        if (rest.value !== value) {
            setValue(rest.value)

            if (onChange) {
                onChange(name, rest.value || '')
            }
        }
    }, [
        name,
        onChange,
        rest.value,
        value,
    ])

    useEffect(() => {
        const option = options?.find((o) => {
            return o.value === value
        })

        if (!option && value !== '') {
            // reset option if not available in option list
            setValue('')
            if (onChange) {
                onChange(name, '')
            }
        } else if (!allowEmpty && options?.length && value === '') {
            // select first option by default
            setValue(options[0].value)
            if (onChange) {
                onChange(name, options[0].value)
            }
        }
    }, [
        allowEmpty,
        name,
        onChange,
        options,
        value,
    ])

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            setValue(e.target.value)
            if (onChange) {
                onChange(name, e.target.value)
            }
        },
        [
            onChange,
            name,
        ],
    )

    const handleBlur = useCallback(() => {
        if (onBlur) {
            onBlur(name)
        }
    }, [
        onBlur,
        name,
    ])

    const getOption = useCallback((option: ISelectOption<object>) => {
        if (getOptionLabel) {
            return getOptionLabel(option)
        }
        return option.label
    }, [getOptionLabel])

    return (
        <div css={classes.root}>
            {label && (
                <Typography
                    variant="label"
                    css={classes.label}
                >
                    {label}
                </Typography>
            )}
            <select
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
            >
                {options?.map((option) => {
                    return (
                        <option
                            key={option.value}
                            value={option.value}
                        >
                            {getOption(option)}
                        </option>
                    )
                })}
            </select>
            {helperText}
        </div>
    )
}

export default Select
