import {
    css,
} from '@emotion/react'
import React, {
    ReactNode, useEffect, useRef, useState,
} from 'react'
import ReactDOM from 'react-dom'

import useT from '@src/hooks/useT/useT'

type Props = {
    className?: string
    isLoading: boolean
    children?: JSX.Element | JSX.Element[] | ReactNode
}

const LoadingIcon = ({
    isLoading,
}: Pick<Props, 'isLoading'>): JSX.Element => {
    const {
        t,
    } = useT()

    return ReactDOM.createPortal(
        isLoading ? <div css={styles.loadingIcon}>{t('LOADING')}</div> : null,
        document.getElementById('root') as HTMLElement,
    )
}

function Loading({
    className, isLoading, children,
}: Props): JSX.Element {
    const [
        loading,
        setLoading,
    ] = useState(false)
    const cancelationRef = useRef(0)

    useEffect(() => {
        if (cancelationRef.current) {
            clearTimeout(cancelationRef.current)
        }

        if (isLoading) {
            setLoading(true)
        } else {
            cancelationRef.current = window.setTimeout(() => {
                setLoading(false)
            }, 400)
        }
        return () => {
            return window.clearTimeout(cancelationRef.current)
        }
    }, [isLoading])
    return (
        <div
            css={styles.loading}
            className={className}
        >
            <div css={styles.panel(loading)}>{children}</div>
            <LoadingIcon isLoading={loading} />
        </div>
    )
}

const styles = {
    loading: css`
        width: 100%;
        height: 100%;
        min-height:100%;
        position: relative;
    `,

    panel: (loading: boolean) => {
        return [
            css`
                width: 100%;
                min-height:100%;
                height: 100%;`,
            loading && css`             
                    z-index: 998;
                    opacity: 0.4;
                `,
        ]
    },

    loadingIcon: css`
        position: fixed;
        left: 50%;
        top: 50vh;
        transform: translateX(-50%);
        z-index: 999;
    `,
}

export default Loading
