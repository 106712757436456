import {
    AxiosRequestConfig,
} from 'axios'

import useJWTToken from '@src/hooks/useJWTToken'

const useGetAxiosConfig = (): AxiosRequestConfig => {
    const token = useJWTToken()

    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
}

export default useGetAxiosConfig
