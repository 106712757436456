import React, {
    useState,
} from 'react'

import SopEmailSvg from '@src/assets/sop_email.svg'
import Button from '@src/shared-components/Button'
import Layout from '@src/Layout'
import Header from '@src/Layout/components/Header'
import MainPopup from '@src/MainPopup'
import useT from '@src/hooks/useT/useT'
import Loading from '@src/shared-components/Loading'

import classes from './Confirm.style'

type Props = {
  isLoading: boolean;
  onConfirm: () => void;
};

function Confirm({
    onConfirm, isLoading,
}: Props): JSX.Element {
    const {
        t,
    } = useT()
    const [
        isPopupOpened,
        showMainPopup,
    ] = useState(false)

    return (
        <Loading isLoading={isLoading}>
            <Layout
                header={(
                    <Header
                        title={t('ONBOARDING')}
                        onMenuClick={() => {
                            return showMainPopup((open) => {
                                return !open
                            })
                        }}
                    />
                )}
                footer={(
                    <Button
                        variant="primary"
                        onClick={onConfirm}
                    >
                        {t('CONFIRM')}
                    </Button>
                )}
            >
                <MainPopup
                    isOpen={isPopupOpened}
                    onClose={() => {
                        return showMainPopup(false)
                    }}
                />
                <div css={classes.root}>
                    <img
                        alt="Sop email"
                        src={SopEmailSvg}
                        css={classes.icon}
                    />
                    <div css={classes.title}>{t('CONFIRM_HEADER')}</div>

                    <div css={classes.text}>{t('CONFIRM_DESCRIPTION')}</div>
                </div>
            </Layout>
        </Loading>
    )
}

export default Confirm
