import React, {
    ReactNode, useCallback, useEffect, useState,
} from 'react'
import {
    isEmpty,
} from 'lodash'

import Typography from '../Typography'

import classes from './Input.style'

export type TVariant = 'primary' | 'secondary'
export interface IInputProps {
    variant?: TVariant
    type?: string
    label?: ReactNode
    name: string
    className?: string
    text?: string
    placeholder?: ReactNode
    value?: string
    onChange?: (name: string, value: string) => void
    onBlur?: (name: string) => void
    helperText?: ReactNode,
}

function Input({
    type = 'text',
    className,
    label,
    name,
    variant = 'primary',
    placeholder,
    helperText,
    onChange,
    onBlur,
    ...rest
}: IInputProps): JSX.Element {
    const [
        value,
        setValue,
    ] = useState(rest.value)

    useEffect(() => {
        setValue(rest.value)
    }, [rest.value])

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value)
            if (onChange) {
                onChange(name, e.target.value)
            }
        },
        [
            onChange,
            name,
        ],
    )

    const handleBlur = useCallback(() => {
        if (onBlur) {
            onBlur(name)
        }
    }, [
        onBlur,
        name,
    ])

    return (
        <div>
            {label && (
                <Typography
                    variant="label"
                    css={classes.label(variant)}
                >
                    {label}
                </Typography>
            )}
            <div
                css={classes.inputContainer(variant)}
                className={className}
            >
                <input
                    type={type}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <label>{placeholder}</label>
            </div>
            <div css={classes.helperText(variant, isEmpty(helperText))}>{helperText}</div>
        </div>
    )
}

export default Input
